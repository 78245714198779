<template>
  <div class="index-box">
    <div class="index-banner">
      <div class="banner-center">
        <div class="center-text-big">{{ $t("BACK基金會私募計劃，全程公開透明") }}</div>
        <div class="center-text-small">
          {{ $t("A輪計劃已開啟，盡情參與") }}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="index-title">
        <div class="title-en">MORE CHOICES</div>
        <div class="title-zh">{{ $t("BACK基金会私募计划") }}</div>
      </div>
      <div class="content-list">
        <div class="content-item" v-for="(item, index) in 3" :key="index">
          <div class="top-bg">{{ $t("BACK基金会私募计划") }}</div>
          <div class="lis">
            <div class="li flex-between">
              <div class="li-title">{{ $t("活動名稱") }}：</div>
              <div class="li-value">
                <span class="deep-color fw-bold">{{ $t("BACK基金会私募计划") }}</span>
                <span class="blue-color fw-bold">&nbsp;{{index + 1}}</span>
              </div>
            </div>
            <div class="li flex-between">
              <div class="li-title">{{ $t("活動時間") }}：</div>
              <div class="li-value" v-if="modeInfo.length">
                {{dateFormat("yyyy.MM.dd",new Date(modeInfo[index].start * 1000))}}
                -
                {{dateFormat("yyyy.MM.dd",new Date(modeInfo[index].end * 1000))}}
              </div>
            </div>
            <div class="li flex-between">
              <div class="li-title">{{ $t("初始釋放時間") }}：</div>
              <div class="li-value" v-if="modeInfo.length">{{dateFormat("yyyy.MM.dd",new Date(modeInfo[index].releaseTime * 1000))}}</div>
            </div>
            <div class="li flex-between">
              <div class="li-title">{{ $t("IDO價格") }}：</div>
              <div class="li-value tag blue-color" v-if="modeInfo.length">
                1 BACK = {{new BigNumber(modeInfo[index].price).dividedBy(Math.pow(10, 18)).toFixed()}} USDQ
              </div>
            </div>
            <div class="li flex-between">
              <div class="li-title">{{ $t("鎖倉時長") }}：</div>
              <div class="li-value blue-color" v-if="modeInfo.length">{{modeInfo[index].period}}{{ $t("天") }}</div>
            </div>
          </div>
          <div class="btns flex-center">
            <div class="btn left-btn" @click="$router.push({name: 'PlaceMentBuy', query: {index: index + 1}})">{{ $t("購買") }}</div>
            <div class="btn right-btn" @click="$router.push({name: 'PlaceMentReceive', query: {index: index + 1}})">{{ $t("領取") }}</div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch } from "vue";
import { useStore } from "vuex";
import { config } from "@/utils/config";
import { useI18n } from "vue-i18n";
import tokenAbi from "@/utils/abi";
import backico from "@/utils/backico";
import BigNumber from "bignumber.js";
export default {
  components: {},
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const data = reactive({
      BigNumber: BigNumber,
      web3: computed(() => store.state.provider),
      address: computed(() => store.state.accounts[0]),
      backicoContract: null,
      USDQContract: null,
      modeInfo: [],
      initContract: () => {
        data.backicoContract = new data.web3.eth.Contract(backico, config.backicoAddress);
        data.USDQContract = new data.web3.eth.Contract(tokenAbi, config.USDQAddress);
        data.initData();
      },
      initData: () => {
        data.getInfo();
      },
      getInfo: async () => {
        let result1 = await data.backicoContract.methods.getModeByIndex(1).call();
        let result2 = await data.backicoContract.methods.getModeByIndex(2).call();
        let result3 = await data.backicoContract.methods.getModeByIndex(3).call();
        data.modeInfo = [{...result1}, {...result2}, {...result3}]
      },
    });
    if (data.address) {
      data.initContract();
    }
    watch(
      () => data.address,
      val => {
        if (val) {
          data.initContract();
        }
      }
    );
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.li-value {
  text-align: right;
}
.index-banner {
  margin: auto;
  height: 330px;
  background: url(~@/assets/image/pc/index_top.png) no-repeat center / 100% 100%;
  .banner-center {
    max-width: 1400px;
    margin: 0 auto;
    overflow: hidden;
    .center-text-big {
      color: #fff;
      font-size: 50px;
      font-weight: bold;
      margin-top: 80px;
    }
    .center-text-small {
      color: #fff;
      margin-top: 10px;
      font-size: 22px;
    }
    .center-text-btn {
      display: block;
      cursor: pointer;
      margin-top: 30px;
      width: 120px;
      height: 34px;
      background: #fff;
      border-radius: 17px;
      text-align: center;
      line-height: 34px;
      color: #0450c6;
      font-size: 16px;
      font-weight: bold;
      transition: 0.5s;
      &:hover {
        background: #d2e2ff;
        // color: #fff;
      }
    }
  }
}
.content {
  max-width: 1400px;
  margin: 0 auto;
  .index-title {
    margin-top: 35px;
    min-height: 76px;
    text-align: center;
    position: relative;
    .title-en {
      color: rgba(62, 63, 74, 0.17);
      font-size: 38px;
      font-weight: 400;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .title-zh {
      color: #111;
      font-size: 32px;
      font-weight: bold;
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    &::after {
      position: absolute;
      content: "";
      width: 50px;
      height: 4px;
      border-radius: 2px;
      background: #0956d0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .content-list {
    max-width: 1400px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &::after {
      content: '';
      display: block;
      width: 420px;
      height: 0;
    }
    .content-item {
      cursor: pointer;
      width: 420px;
      background: #fff;
      border-radius: 20px;
      overflow: hidden;
      text-align: center;
      margin-top: 50px;
      padding: 20px 20px 37px;
      box-sizing: border-box;
      &:nth-child(n+1) {
        .top-bg {
          background: url(~@/assets/image/placement/top_bg1.jpg) top center/100% 100%;
        }
      }
      &:nth-child(n+2) {
        .top-bg {
          background: url(~@/assets/image/placement/top_bg2.jpg) top center/100% 100%;
        }
      }
      &:nth-child(n+3) {
        .top-bg {
          background: url(~@/assets/image/placement/top_bg3.jpg) top center/100% 100%;
        }
      }
      .top-bg {
        height: 98px;
        font-size: 28px;
        // font-family: Microsoft YaHei;
        text-align: center;
        line-height: 98px;
        font-weight: 400;
        color: #FFFFFF;
      }
      .lis {
        font-size: 16px;
        font-weight: 400;
        color: #383838;
        line-height: 32px;
        padding: 12px 0 0 0;
        .li {
          align-items: flex-start;
          padding: 2px 0;
          .li-title {
            white-space: nowrap;
          }
          .li-value {
            &.tag {
              line-height: 32px;
              padding: 0 10px;
              height: 32px;
              background: #CEDDF6;
              border-radius: 16px;
            }
          }
        }
      }
      .btns {
        margin-top: 12px;
        .btn {
          width: 150px;
          height: 42px;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 42px;
          text-align: center;
          &.left-btn {
            background: url(~@/assets/image/placement/left_btn.png) top center/100% 100%;
          }
          &.right-btn {
            margin-left: -16px;
            background: url(~@/assets/image/placement/right_btn.png) top center/100% 100%;
          }
        }
      }
    }
  }
}
.deep-color {
  color: #111111!important;
}
.blue-color {
  color: #0956D0!important;
}
.fw-bold {
  font-weight: bold!important;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
  .index-banner .banner-center .center-text-big {
    margin-top: 40px;
    font-size: 45px;
  }
}
@media screen and (max-width: 480px) {
  .index-banner {
    height: 165px;
    background: url(~@/assets/image/wap/index_top.png) no-repeat center / 100%
      100%;
    .pc-banner {
      display: none;
    }
    .mobile-banner {
      display: block;
    }
    .banner-center {
      margin-left: 16px;
      .center-text-big {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-top: 38px;
      }
      .center-text-small {
        font-size: 11px;
        margin-top: 5px;
        color: #fff;
      }
      .center-text-btn {
        width: 70px;
        height: 25px;
        font-size: 8px;
        font-weight: bold;
        border-radius: 25px;
        line-height: 25px;
        margin-top: 17px;
      }
    }
  }
  .content {
    .index-title {
      margin: 20px 60px 0;
      min-height: 49px;
      .title-en {
        font-size: 19px;
        top: 11px;
        left: 0;
        right: 0;
      }
      .title-zh {
        font-weight: bold;
        color: #111;
        font-size: 18px;
      }
      &::after {
        position: absolute;
        content: "";
        width: 25px;
        height: 2px;
        border-radius: 2px;
        background: #0956d0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .content-list {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;
      display: block;
      padding-bottom: 40px;
      .content-item {
        width: 90%;
        cursor: none;
        margin: 15px auto;
        padding: 20px 15px 20px;
        .top-bg {
          font-size: 22px;
        }
        .lis .li {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
