<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <div class="common-page--menu"><div class="link link-white" @click="$router.push({ name: 'Index' })">{{$t("首頁")}}&nbsp;>&nbsp;</div><div class="link link-white" @click="$router.go(-1)">IDO&nbsp;>&nbsp;</div><div class="link">{{$t("BACK基金私募領取")}}</div></div>
      <div class="top-header flex-center">
        <div class="header-center">
          {{ $t("BACK基金私募") }}<div class="point"></div>{{$t("領取")}}
        </div>
      </div>
    </div>
    <el-form class="from-box">
      <div class="form-inner" v-if="modeInfo">
        <div class="li flex-between">
          <div class="li-title">{{ $t("活動名稱") }}：</div>
          <div class="li-value">{{ $t("BACK基金會私募計劃") }}{{index}}</div>
        </div>
        <div class="li flex-between">
          <div class="li-title">{{ $t("初始釋放時間") }}：</div>
          <div class="li-value">
            {{dateFormat("yyyy.MM.dd",new Date(modeInfo.releaseTime * 1000))}}
          </div>
        </div>
        <div class="li flex-between">
          <div class="li-title">{{ $t("IDO價格") }}：</div>
          <div class="li-value">1 BACK = {{new BigNumber(modeInfo.price).dividedBy(Math.pow(10, 18)).toFixed()}} USDQ</div>
        </div>
        <div class="li flex-between">
          <div class="li-title">{{ $t("鎖倉時長") }}：</div>
          <div class="li-value">{{modeInfo.period}}{{ $t("天") }}</div>
        </div>
      </div>
      <template v-if="userData">
        <div class="input-suffix flex-between">
          <div class="input-label">{{$t("鎖倉數量")}}：</div>
          <div class="input-value">
            <span class="blue-color fw-bold">
              {{BigNumber(userData.icoAmount).dividedBy(Math.pow(10, 18)).toFixed(6, 1)}}
            </span> <span class="deep-color fw-bold">BACK</span>
          </div>
        </div>
        <div class="input-suffix flex-between">
          <div class="input-label">{{$t("已領取數量")}}：</div>
          <div class="input-value">
            <span class="blue-color fw-bold">
              {{BigNumber(userData.claimedAmount).dividedBy(Math.pow(10, 18)).toFixed(6, 1)}}
            </span> <span class="deep-color fw-bold">BACK</span>
          </div>
        </div>
        <div class="input-suffix flex-between">
          <div class="input-label">{{$t("可領取數量")}}：</div>
          <div class="input-value">
            <span class="blue-color fw-bold">
              {{BigNumber(userData.pendingUnlocked).dividedBy(Math.pow(10, 18)).toFixed(6, 1)}}
            </span> <span class="deep-color fw-bold">BACK</span>
          </div>
        </div>
      </template>
      <div class="form-btn flex-between">
        <el-button
          type="primary"
          @click="$router.go(-1)"
          block
          round
          >{{ $t("取消") }}</el-button>
        <el-button
          v-if="!address"
          type="primary"
          block
          round
          @click="initWallet"
          >{{ $t("鏈接錢包") }}</el-button
        >
        <el-button
          v-else
          type="primary"
          block
          round
          :disabled="loading"
          :loading="loading"
          @click="toClaim"
          >{{ $t("領取") }}</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch } from "vue";
import WalletContract from "@/components/WalletContract";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import LPStake from "@/utils/LPStake";
import { config } from "@/utils/config";
import BigNumber from "bignumber.js";
import tokenAbi from "@/utils/abi";
import web3Data from "@/utils/initWeb3";
import { ElMessage } from "element-plus";
import backico from "@/utils/backico";
import { useI18n } from "vue-i18n";
export default {
  components: {
    WalletContract
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const data = reactive({
      BigNumber: BigNumber,
      index: route.query.index,
      web3: computed(() => store.state.provider),
      address: computed(() => store.state.accounts[0]),
      backicoContract: null,
      USDQContract: null,
      modeInfo: null,
      userData: null,
      loading: false,
      initWallet: () => {
        if (!data.address) {
          web3Data.initWeb3();
        }
      },
      initContract: () => {
        data.backicoContract = new data.web3.eth.Contract(backico, config.backicoAddress);
        data.initData();
      },
      initData: () => {
        data.getInfo();
      },
      getInfo: async () => {
        let result = await data.backicoContract.methods.getModeByIndex(data.index).call();
        data.modeInfo = {...result}
        let userData = await data.backicoContract.methods.getUserData(data.address, data.index).call();
        data.userData = userData
      },
      toClaim () {
        data.loading = true;
        data.backicoContract.methods.claim(data.index).estimateGas({ from: data.address })
        .then(gas => {
          data.backicoContract.methods.claim(data.index).send({ from: data.address, gas: gas })
          .then(() => {
            ElMessage.success(t("交易成功"));
            data.loading = false;
            data.initData()
          })
          .catch(() => {
            data.loading = false;
            ElMessage.error(t("交易失敗"));
          });
        })
        .catch(err => {
          console.log(err)
          data.loading = false;
          let errStr = err.toString()
          if (errStr.includes('Releasing not start')) {
            ElMessage.error(t("釋放未開始"));
          }
          if (errStr.includes('modeId not exist')) {
            ElMessage.error('modeId not exist');
          }
          if (errStr.includes('No Pending Unlocked Token')) {
            ElMessage.error('No Pending Unlocked Token!');
          }
        });
      }
    });
    if (data.address) {
      data.initContract();
    }
    watch(
      () => data.address,
      val => {
        if (val) {
          data.initContract();
        }
      }
    );
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.from-box .input-suffix .input-label {
  white-space: nowrap;
  font-size: 18px;
}
.from-box .input-suffix .input-value {
  font-size: 20px;
}
/deep/.from-box .el-input-group__append {
  font-size: 20px;
}
/deep/.el-button+.el-button {
  margin-left: 0;
}
.topbg-wrapper .top-bg {
  height: 330px;
  background: url(~@/assets/image/pc/index_top.png) no-repeat center/100% 100%;
}
.topbg-wrapper .top-bg .top-header .header-center {
  padding-top: 70px;
}
.topbg-wrapper .from-box {
  margin-top: -104px;
}
.header-center {
  .point {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fff;
    position: relative;
    top: -10px;
    margin: 0 1px;
  }
}
.from-box {
  padding: 30px 35px;
  .form-inner {
    background: #F3F5F8;
    padding: 10px 8px;
    margin-bottom: 15px;
    .li {
      font-size: 14px;
      font-weight: 400;
      color: #4A4A4A;
      line-height: 32px;
      align-items: flex-start;
      .li-title {
        white-space: nowrap;
      }
      .li-value {
        text-align: right;
      }
    }
  }
  .input-suffix {
    font-size: 18px;
    font-weight: 400;
    color: #111111;
    line-height: 56px;
    padding: 0 8px;
  }
  .form-btn {
    margin: 22px 0 0 0;
    padding: 0 5px;
  }
}
.blue-color {
  color: #0956D0!important;
}
.deep-color {
  color: #111!important;
}
.fw-bold {
  font-weight: bold!important;
}
.form-btn {
  .el-button:first-child {
    margin-right: 30px;
    color: #0956D0;
    border: 1px solid #0956D0;
    background: #fff;
  }
}
@media screen and (max-width: 480px) {
.from-box .input-suffix .input-label {
  white-space: nowrap;
  font-size: 14px;
}
.from-box .input-suffix .input-value {
  font-size: 16px;
}
/deep/.from-box .el-input-group__append {
  font-size: 16px;
}
  .header-center .point {
    width: 8px;
    height: 8px;
  }
  .topbg-wrapper .top-bg {
    height: 155px;
    background-size: auto 100%;
  }
  .topbg-wrapper .top-bg .top-header .header-center {
    padding-top: 60px;
  }
  .from-box .input-suffix {
    line-height: 40px;
  }
  .header-center .point {
    top: -3px;
  }
  .topbg-wrapper .from-box {
    padding: 15px 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  /deep/ .suffix-span {
    padding: 0 6px 0 0 !important;
    font-size: 15px !important;
    border-right: 1px solid #d4d4d4;
    margin-right: -6px;
  }
  /deep/ .from-box .el-input__inner {
    // width: 150px !important;
    font-size: 14px !important;
    padding-right: 35px !important;
    background: #eff2f7;
    border: 0;
  }
  /deep/ .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 7px 0 5px;
  }
  /deep/ .el-input__suffix {
    transform: translate(-39px, -12px) !important;
  }
  /deep/ .from-box .input-suffix .suffix-span {
  }
  /deep/ .from-box .input-suffix .input-label {
    margin-right: 0;
  }
  /deep/ .from-box {
    margin-top: -150px;
  }
  /deep/ .el-input--suffix .el-input__inner {
    padding-right: 45px;
  }
  .from-box {
    padding: 0 15px;
  }
  /deep/ .el-input-group__append,
  .el-input-group__prepend {
    background: #eff2f7;
    border: 0;
  }
  /deep/ .el-input-group {
    background: #eff2f7;
    border-radius: 6px;
  }
}
</style>
