<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <header-search></header-search>
    </div>
    <div class="bottom-table">
      <div class="table-box">
        <div class="table-title">{{ $t("代幣") }}</div>
        <el-table :data="blockList" style="width: 100%">
          <el-table-column :label="$t('合約地址')" width="500">
            <template #default="scope">
              <router-link
                :to="{
                  name: 'AddressDetail',
                  params: { address: scope.row.contract }
                }"
                >{{ scope.row.contract }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('代幣簡稱')">
            <template #default="scope">
              <span class="text-ellipsis">{{ scope.row.symbol }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="decimals"
            :label="$t('代幣精度')"
          ></el-table-column>
          <el-table-column
            prop="totalSupply"
            :label="$t('代幣總量')"
          ></el-table-column>
        </el-table>
      </div>
      <div class="pagition-section">
        <el-pagination
          background
          :page-size="limit"
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page="Number(page)"
          :total="total"
          :pager-count="5"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getTokenAllList } from "@/api";
import { useI18n } from "vue-i18n";
import HeaderSearch from "@/components/HeaderSearch";
export default {
  components: {
    HeaderSearch
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const data = reactive({
      $t: t,
      page: route.query.page || 1,
      limit: 30,
      total: 0,
      blockList: [],
      currentChange: number => {
        router.replace({ name: "Token", query: { page: number } });
      },
      toDetail: (row, column, event) => {
        router.push({
          name: "BlockDetial",
          params: { blockNumber: row.number }
        });
      }
    });
    onMounted(() => {
      getTokenAllList({ page: data.page, limit: data.limit }).then(res => {
        data.blockList = res.data.list;
        data.total = res.data.total;
      });
    });
    onBeforeRouteUpdate((to, from) => {
      data.page = to.query.page || 1;
      getTokenAllList({ page: data.page, limit: data.limit }).then(res => {
        data.blockList = res.data.list;
        data.total = res.data.total;
      });
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.bottom-table {
  margin: -80px auto 80px;
}
@media screen and (max-width: 480px) {
  .bottom-table {
    margin: -20px auto 20px;
  }
}
</style>
