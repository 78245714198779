<template>
  <div class="index-box">
    <div class="index-banner">
      <div class="banner-center">
        <div class="center-text-big">{{ $t("BACK代幣分配細則") }}</div>
      </div>
    </div>
    <div class="content">
      <div class="detail-box" :style="{'background': bgColor}" v-if="$isInMobile && seriseItem && seriseItem.name">
        <div class="title">{{seriseItem.name}}<span class="percent">({{seriseItem.percent}})</span></div>
        <div class="address clip-button" :data-clipboard-text="seriseItem.address">{{seriseItem.address}}<span class="percent">[{{t('複製')}}]</span></div>
      </div>
      <div class="chart-box">
        <div id="chart" :style="$isInMobile ? 'width: 100%; height: 200px;' : 'width: 100%; height: 700px;'"></div>
      </div>
      <div class="table">
        <div class="th flex-between">
          <div class="td">{{$t('分配項目')}}</div>
          <div class="td">{{$t('數額')}}</div>
          <div class="td">{{$t('具體用途')}}</div>
        </div>
        <div class="tbody">
          <div class="tr flex-between">
            <div class="td"><div class="td-inner">BACK{{$t('基金會')}}<span class="tip">(10%)</span></div></div>
            <div class="td">21000000  BACK</div>
            <div class="td">{{$t('用於網絡鑄造BACK')}}</div>
          </div>
          <div class="tr flex-between">
            <div class="td"><div class="td-inner">BACK{{$t('生態建設')}}<span class="tip">(30%)</span></div></div>
            <div class="td">63000000  BACK</div>
            <div class="td">{{$t('用於生態建設發展')}}</div>
          </div>
          <div class="tr flex-between">
            <div class="td"><div class="td-inner">BACK{{$t('私募')}}<span class="tip">(10%)</span></div></div>
            <div class="td">21000000  BACK</div>
            <div class="td">{{$t('用於購買代幣')}}</div>
          </div>
          <div class="tr flex-between">
            <div class="td"><div class="td-inner">BACK{{$t('易趣分紅')}}<span class="tip">(25%)</span></div></div>
            <div class="td">52500000  BACK</div>
            <div class="td">{{$t('通過區塊獎勵、網絡初始化等方式分給存儲提供者的代幣獎勵')}}</div>
          </div>
          <div class="tr flex-between">
            <div class="td"><div class="td-inner">BACK{{$t('流動性挖礦')}}<span class="tip">(25%)</span></div></div>
            <div class="td">52500000  BACK</div>
            <div class="td">{{$t('用於獎勵分紅')}}</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import poolList from "@/utils/poolList";
import { config } from "@/utils/config";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import { echarts } from '@/utils/echarts.js'
import Clipboard from 'clipboard'
export default {
  components: {},
  setup() {
    const { proxy } = getCurrentInstance()
    onMounted(() => {
      console.log(proxy.$isInMobile, 'proxy.$isInMobile')
      if (proxy.$isInMobile) {
        data.option = data.optionMobile
      } else {
        data.option = data.optionPC
      }
      data.chart = echarts.init(document.getElementById('chart'));
      data.setChartOption()
      data.chart.on('click', function (params) {
        console.log(params, 'params')
        data.seriseItem = {
            name: data.serise[params.dataIndex].name,
            percent: params.percent + '%',
            address: params.data.address
        }
        data.bgColor = data.colorArr[params.dataIndex]
        if (params.event.target.currentStates.length > 0) {
          data.setChartOption(params.dataIndex)
        } else {
          if (!proxy.$isInMobile) {
            data.copy(params.data.address)
          }
        }
      });
      window.addEventListener("resize", () => { data.chart.resize()})
    })
    const store = useStore();
    const { t } = useI18n();
    const data = reactive({
      colorArr: ["#86D560", "#AF89D6", "#59ADF3", "#FF999A", "#FFCC67"],
      chart: null,
      serise: [
        {
          value: 21000000,
          address: config.jijinhuiAddress,
          name: "BACK" + t('基金會'),
        },
        {
          value: 63000000,
          address: config.transferAddress,
          name: "BACK" + t('生態建設'),
        },
        {
          value: 21000000,
          address: config.backicoAddress,
          name: "BACK" + t('私募'),
        },
        {
          value: 52500000,
          address: config.yiquefenhongAddress,
          name: "BACK" + t('易趣分紅'),
        },
        {
          value: 52500000,
          address: config.LPStake,
          name: "BACK" + t('流動性挖礦'),
        },
      ],
      t: t,
      bgColor: "",
      seriseItem: {},
      option: {},
      optionMobile: {
        title: {
          text: "BACK " +t("代幣")+ "\n" + t("分配細則"),
          left: "center",
          top: "center",
          textStyle: {
            fontSize: 12,
            color: '#111111',
            fontWeight: 'normal'
          }
        },
        tooltip: {
          show: !proxy.$isInMobile,
          trigger: "item",
          formatter: "{b} : {c} ({d}%)"
        },
        series: [
          {
            hoverAnimation: false,
            type: "pie",
            radius: ["25%", "55%"],
            center: ["50%", "50%"],
            selectedMode: "single",
            itemStyle: {
              color: function (params) {
                return data.colorArr[params.dataIndex]
              }
            },
            labelLine: {
              length: 0,
              length2: 0
            },
            avoidLabelOverlap: true,
            data: []
          }
        ]
      },
      optionPC: {
        title: {
          text: "BACK " +t("代幣")+ "\n" + t("分配細則"),
          left: "center",
          top: "center",
          textStyle: {
            fontSize: 28,
            color: '#111111',
            fontWeight: 'normal'
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        series: [
          {
            hoverAnimation: false,
            type: "pie",
            radius: ["25%", "65%"],
            center: ["50%", "50%"],
            selectedMode: "single",
            itemStyle: {
              color: function (params) {
                return data.colorArr[params.dataIndex]
              }
            },
            labelLine: {
              length: 10,
              length2: 40
            },
            avoidLabelOverlap: true,
            data: []
          }
        ]
      },
      activeIndex: '',
      local: computed(() => store.state.locale),
      themeMode: computed(() => store.state.themeMode),
      poolPairList: poolList,
      rate: computed(() => store.state.rate),
      ratekis: computed(() => store.state.ratekis),
      web3: computed(() => store.state.provider),
      setChartOption (activeIndex) {
        var arr = []
        if (activeIndex !== undefined || activeIndex !== null) {
          data.activeIndex = activeIndex
          data.serise.forEach((item, index) => {
            if (activeIndex === index) {
              arr.push({
                value: item.value,
                address: item.address,
                name: item.name,
                label: {
                  show: true,
                  overflow: 'breakAll',
                  padding: proxy.$isInMobile ? [10, 5, 10, 5] : [20, 10, 10, 15],
                  width: proxy.$isInMobile ? 90 : 240,
                  formatter: function (params) {
                    return [
                      "{title|"+params.name+"}{tip|("+params.percent+"%)}\n",
                      "{split|}",
                      "{text|"+t('合約地址')+"：}\n",
                      "{text|"+params.data.address+"}{copy|["+t('複製')+"]}",
                    ].join("\n")
                  },
                  backgroundColor: data.colorArr[index],
                  borderRadius: 10,
                  rich: {
                    title: {
                      align: 'left',
                      color: "#000",
                      fontSize: proxy.$isInMobile ? 12: 18,
                      fontWeight: 'bold'
                    },
                    tip: {
                      align: 'left',
                      color: '#0956D0',
                      fontSize: proxy.$isInMobile ? 12 : 18,
                      fontWeight: 'bold'
                    },
                    split: {
                      height: 5,
                    },
                    text: {
                      align: 'left',
                      fontSize: proxy.$isInMobile ? 10 : 14,
                      color: '#000',
                      lineHeight: proxy.$isInMobile ? 14 : 22
                    },
                    copy: {
                      align: 'left',
                      fontSize: proxy.$isInMobile ? 10 : 14,
                      color: '#0956D0',
                      lineHeight: proxy.$isInMobile ? 14 : 22
                    }
                  }
                }
              })
            } else {
              arr.push({
                value: item.value,
                address: item.address,
                name: item.name,
                label: {
                  formatter: ["{a|{b}} {b|({d}%)}"].join("\n"),
                  rich: {
                    a: {
                      align: 'left',
                      color: '#000000',
                      fontWeight: 'normal',
                      fontSize: proxy.$isInMobile ? 10 : 16
                    },
                    b: {
                      align: 'left',
                      color: '#0956D0',
                      fontWeight: 'normal',
                      fontSize: proxy.$isInMobile ? 10 : 16
                    }
                  }
                }
              })
            }
          })
        } else {
          data.serise.forEach((item, index) => {
            data.activeIndex = ''
            arr.push({
              value: item.value,
              address: item.address,
              name: item.name,
              label: {
                formatter: ["{a|{b}} {b|({d}%)}"].join("\n"),
                rich: {
                  a: {
                    align: 'left',
                    color: '#000000',
                    fontWeight: 'normal',
                    fontSize: proxy.$isInMobile ? 10 : 16
                  },
                  b: {
                    align: 'left',
                    color: '#0956D0',
                    fontWeight: 'normal',
                    fontSize: proxy.$isInMobile ? 10 : 16
                  }
                }
              }
            })
          })
        }
        if (proxy.$isInMobile && activeIndex !== undefined) {
          console.log('111', activeIndex, proxy.$isInMobile)
        } else {
          data.option.series[0].data = arr
          data.chart.setOption(data.option)
          console.log(data.option)
        }
      },
      copy (value) {
        let transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.style.cssText = 'position: absolute;right: 45%;top: 80%;'
        transfer.value = value 
        transfer.focus()
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        ElMessage.success(t("複製成功"));
        document.body.removeChild(transfer)
      }
    });
    let clipboard = new Clipboard('.clip-button')
    clipboard.on('success', function (e) {
      ElMessage.success(t("複製成功"));
      e.clearSelection()
    })
    clipboard.on('error', function () {
      ElMessage.success(t("複製失敗"))
    })
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.index-banner {
  margin: auto;
  height: 280px;
  background: url(~@/assets/image/placement/dis_bg.jpg) no-repeat center / 100% 100%;
  .banner-center {
    max-width: 1400px;
    margin: 0 auto;
    overflow: hidden;
    .center-text-big {
      color: #fff;
      font-size: 50px;
      font-weight: bold;
      margin-top: 120px;
      text-align: center;
    }
    .center-text-small {
      color: #fff;
      margin-top: 10px;
      font-size: 22px;
    }
    .center-text-btn {
      display: block;
      cursor: pointer;
      margin-top: 30px;
      width: 120px;
      height: 34px;
      background: #fff;
      border-radius: 17px;
      text-align: center;
      line-height: 34px;
      color: #0450c6;
      font-size: 16px;
      font-weight: bold;
      transition: 0.5s;
      &:hover {
        background: #d2e2ff;
        // color: #fff;
      }
    }
  }
}
.content {
  max-width: 1400px;
  margin: -55px auto 0;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 0 0 100px 0;
  margin-bottom: 30px;
}
.table {
  margin: 0 115px;
  background: #fff;
  border: 1px solid #E6E6E6;
  text-align: center;
  .th {
    background: #F4F9FF;
    border-bottom: 1px solid #E6E6E6;
    color: #0956D0;
    font-size: 16px;
    line-height: 56px;
    font-weight: 600;
  }
  .td {
    height: 56px;
    &:nth-child(1), &:nth-child(2) {
      width: 25%;
      border-right: 1px solid #E6E6E6;
    }
    &:nth-child(3) {
      width: 50%;
    }
  }
  .tr {
    font-weight: 500;
    border-bottom: 1px solid #E6E6E6;
    color: #000;
    font-size: 16px;
    line-height: 20px;
    .td {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:last-child {
      border-bottom: 0;
    }
    .tip {
      color: #0956D0;
    }
    &:nth-child(even) {
      background: #F4F9FF;
    
    }
  }
}
.deep-color {
  color: #111111!important;
}
.blue-color {
  color: #0956D0!important;
}
.fw-bold {
  font-weight: bold!important;
}
</style>
<style lang="scss" scoped>
.detail-box {
  margin: 0 20px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  .title {
    font-size: 14px;
    font-weight: bold;
  }
  .percent {
    color: #0956D0;
    font-weight: 500;
    font-size: 12px;
  }
  .address {
    font-size: 14px;
    margin-top: 5px;
    word-break: break-all;
  }
}
@media screen and (max-width: 480px) {
  .content {
    overflow: hidden;
  }
  .index-banner {
    height: 165px;
    background: url(~@/assets/image/wap/index_top.png) no-repeat center / 100%
      100%;
    .pc-banner {
      display: none;
    }
    .mobile-banner {
      display: block;
    }
    .banner-center {
      .center-text-big {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin-top: 38px;
      }
      .center-text-small {
        font-size: 11px;
        margin-top: 5px;
        color: #fff;
      }
      .center-text-btn {
        width: 70px;
        height: 25px;
        font-size: 8px;
        font-weight: bold;
        border-radius: 25px;
        line-height: 25px;
        margin-top: 17px;
      }
    }
  }
  .content {
    padding: 0 0 40px 0;
    .table {
      margin: 20px 0;
      .th {
         font-size: 14px;
         line-height: unset;
      }
      .tr {
        font-size: 14px;
        line-height: 18px;
        word-break: break-all;
      }
      .td {
        min-height: 50px;
        height: auto;
        padding: 10px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
