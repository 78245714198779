<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <header-search></header-search>
    </div>
    <div class="bottom-table">
      <div class="table-box">
        <div class="table-title">{{ $t("區塊") }}</div>
        <el-table :data="blockList" style="width: 100%">
          <el-table-column :label="$t('區塊高度')" width="130px">
            <template #default="scope">
              <router-link
                :to="{
                  name: 'BlockDetial',
                  params: { blockNumber: scope.row.number }
                }"
                >{{ scope.row.number }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('區塊哈希值')">
            <template #default="scope">
              <span class="text-ellipsis">{{ scope.row.hash }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="130px"
            prop="transactionNum"
            :label="$t('交易次數')"
          ></el-table-column>
          <el-table-column
            width="150px"
            prop="unclesNum"
            :label="$t('叔區塊')"
          ></el-table-column>
          <el-table-column :label="$t('區塊產生者')">
            <template #default="scope">
              <router-link
                class="text-ellipsis"
                :to="{
                  name: 'AddressDetail',
                  params: { address: scope.row.miner }
                }"
                >{{ scope.row.miner }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="gasLimit"
            width="140px"
            :label="$t('燃料限制')"
          ></el-table-column>
          <el-table-column :label="$t('燃料價值')">
            <template #default="scope">
              <span class="text-ellipsis"
                >{{
                  web3.utils.fromWei(scope.row.gasUsed + "", "Gwei")
                }}
                Gwei</span
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('產生時間')">
            <template #default="scope">
              <span class="text-ellipsis">{{
                dateFormat(
                  "yyyy-MM-dd hh:mm:ss",
                  new Date(scope.row.timestamp * 1000)
                )
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagition-section">
        <el-pagination
          background
          :page-size="limit"
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page="Number(page)"
          :total="total"
          :pager-count="5"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, computed } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getBlockAllList } from "@/api";
import { useI18n } from "vue-i18n";
import HeaderSearch from "@/components/HeaderSearch";
import { useStore } from "vuex";
import Web3 from "web3";
export default {
  components: {
    HeaderSearch
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const data = reactive({
      web3: Web3,
      $t: t,
      page: route.query.page || 1,
      limit: 30,
      total: 0,
      blockList: [],
      currentChange: number => {
        router.replace({ name: "BlockList", query: { page: number } });
      },
      toDetail: (row, column, event) => {
        router.push({
          name: "BlockDetial",
          params: { blockNumber: row.number }
        });
      }
    });
    onMounted(() => {
      getBlockAllList({ page: data.page, limit: data.limit }).then(res => {
        data.blockList = res.data.list;
        data.total = res.data.total;
      });
    });
    onBeforeRouteUpdate((to, from) => {
      data.page = to.query.page || 1;
      getBlockAllList({ page: data.page, limit: data.limit }).then(res => {
        data.blockList = res.data.list;
        data.total = res.data.total;
      });
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>

<style lang="scss" scoped>
.bottom-table {
  margin: -80px auto 80px;
}
@media screen and (max-width: 480px) {
  .bottom-table {
    margin: -20px auto 20px;
  }
}
</style>
