<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <header-search></header-search>
    </div>
    <div class="bottom-table">
      <div class="table-box">
        <div class="table-title">{{ $t("交易") }}</div>
        <el-table :data="transactionList" style="width: 100%">
          <el-table-column :label="$t('交易哈希')">
            <template #default="scope">
              <router-link
                class="text-ellipsis"
                :to="{
                  name: 'TransactionDetial',
                  params: { hash: scope.row.hash }
                }"
                >{{ scope.row.hash }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column width="100px" :label="$t('區塊')">
            <template #default="scope">
              <router-link
                :to="{
                  name: 'BlockDetial',
                  params: { blockNumber: scope.row.blockNumber }
                }"
                >{{ scope.row.blockNumber }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('發送方')">
            <template #default="scope">
              <router-link
                class="text-ellipsis"
                :to="{
                  name: 'AddressDetail',
                  params: { address: scope.row.fromAddress }
                }"
                >{{ scope.row.fromAddress }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('接收方')">
            <template #default="scope">
              <router-link
                v-if="scope.row.toAddress"
                class="text-ellipsis"
                :to="{
                  name: 'AddressDetail',
                  params: { address: scope.row.toAddress }
                }"
                >{{ scope.row.toAddress }}</router-link
              >
              <span class="text-ellipsis" v-else>{{ $t("建立合約") }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('價值')">
            <template #default="scope">
              <span class="text-ellipsis"
                >{{
                  web3.utils.fromWei(scope.row.ethValue, "ether")
                }}
                back</span
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('交易費用')">
            <template #default="scope">
              <span class="text-ellipsis"
                >{{ web3.utils.fromWei(scope.row.gasPrice, "Gwei") }} Gwei</span
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('時間')">
            <template #default="scope">
              <span class="text-ellipsis">{{
                dateFormat(
                  "yyyy-MM-dd hh:mm:ss",
                  new Date(scope.row.timestamp * 1000)
                )
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagition-section">
        <el-pagination
          background
          :page-size="limit"
          layout="prev, pager, next"
          @current-change="currentChange"
          :current-page="Number(page)"
          :total="total"
          :pager-count="5"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { getTransactionAllList } from "@/api";
import { useI18n } from "vue-i18n";
import HeaderSearch from "@/components/HeaderSearch";
import Web3 from "web3";
export default {
  components: {
    HeaderSearch
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const route = useRoute();
    const data = reactive({
      web3: Web3,
      $t: t,
      page: route.query.page || 1,
      limit: 30,
      total: 0,
      transactionList: [],
      currentChange: number => {
        router.replace({ name: "Transaction", query: { page: number } });
      },
      toDetail: row => {
        router.push({ name: "TransactionDetial", params: { hash: row.hash } });
      }
    });
    onMounted(() => {
      getTransactionAllList({ page: data.page, limit: data.limit }).then(
        res => {
          data.transactionList = res.data.list;
          data.total = res.data.total;
        }
      );
    });
    onBeforeRouteUpdate(to => {
      data.page = to.query.page || 1;
      getTransactionAllList({ page: data.page, limit: data.limit }).then(
        res => {
          data.transactionList = res.data.list;
          data.total = res.data.total;
        }
      );
    });
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.bottom-table {
  margin: -80px auto 80px;
}
@media screen and (max-width: 480px) {
  .bottom-table {
    margin: -20px auto 20px;
  }
}
</style>
